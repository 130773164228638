import { Box, Spinner } from '@chakra-ui/react';

export default function LoadingSpinner(): JSX.Element {
  return (
    <Spinner
      color="brand.500"
      emptyColor="gray.300"
      size="lg"
      thickness="2px"
    />
  );
}

export const PageLoadingSpinner = () => {
  return (
    <Box
      bg="white"
      h="100vh"
      left={0}
      overflow="hidden"
      position="absolute"
      top={0}
      w="100vw"
      zIndex={9999}
    >
      <Box
        left="50%"
        position="absolute"
        top="50%"
        transform="translate(-50%, -50%)"
      >
        <LoadingSpinner />
      </Box>
    </Box>
  );
};
